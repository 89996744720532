import { useField } from 'formik';

export default function CustomFieldError({ label, ...props }) {
  const [field, meta] = useField(props);

  return (
    <>
      {meta.touched && meta.error && (
        <p className="customError">{meta.error}</p>
      )}
    </>
  );
}
