import inputField from "../formFields/inputField.js";
import datePickerField from "../formFields/datepickerField.js";
import dateTimePickerField from "../formFields/dateTimePickerField.js";
import textAreaField from "../formFields/textAreaField.js";
import selectField from "../formFields/selectField.js";
import radioFieldArray from "../formFields/radioFieldArray.js";
import rioSingleTickCheck from "../formFields/rioSingleTickCheck.js";
import asyncSelectField from "../formFields/asyncSelectField.js";
import checkBoxArrayField from "../formFields/checkBoxFieldArray.js";
import subHeader from "../formFields/subHeader.js";
import moment from "moment";
import { useFormikContext } from "formik";
import { v4 as uuidv4 } from "uuid";

const componentMap = {
  TEXTBOX: inputField,
  DATETIME: dateTimePickerField,
  DATE: datePickerField,
  PICKLIST: selectField,
  TEXTAREA: textAreaField,
  CHECKBOX: rioSingleTickCheck,
  CHECKBOXARRAY: checkBoxArrayField,
  RADIOGROUP: radioFieldArray,
  HEADER: subHeader,
  ASYNCSELECT: asyncSelectField,
  NUMBER: textAreaField,
};

export function OrderedFormData({ data, loading, complete }) {
  const orderedData = data.sort((a, b) => a.y - b.y);

  const formikContext = useFormikContext();

  const values = formikContext ? formikContext.values : null;

  if (complete) {
    return (
      <>
        {orderedData.map((x) => {
          let ret;

          const compKey = uuidv4().substring(0, 8);

          if (x.type === "DATETIME") {
            ret = (
              <p key={compKey}>
                {x.label}:{" "}
                <strong>{moment(x.value).format("YYYY-MM-DD HH:mm:ss")}</strong>
              </p>
            );
          } else if (x.type === "HEADER") {
            ret = <h5 key={compKey}>{x.label}</h5>;
          } else {
            ret = (
              <p key={compKey}>
                {x.label}: <strong>{x.value}</strong>
              </p>
            );
          }

          return ret;
        })}
      </>
    );
  } else {
    return (
      <>
        {orderedData.map((x) => {
          const conditionalDisable =
            x.meta && x.meta.find((x) => x.includes("CON"));

          let dependsOnField;
          let dependsOnValue;

          if (conditionalDisable) {
            const conditionalArrayValues = conditionalDisable.split("-");
            dependsOnField = conditionalArrayValues[1];
            dependsOnValue = conditionalArrayValues[2];
          }

          const Component = componentMap[x.type];
          let ret;
          if (x.type === "CHECKBOX" || x.type === "CHECKBOXARRAY") {
            ret = (
              <Component
                key={x.code}
                name={x.code}
                label={x.label}
                disabled={loading}
                data={x.options} // Data to populate selects
                //Checkboxes to disable
                disabledValueDescription={x.disabledValueDescription}
                disabledValueCode={x.disabledValueCode}
              />
            );
          } else if (x.type === "PICKLIST" || x.type === "RADIOGROUP") {
            ret = (
              <Component
                key={x.code}
                name={x.code}
                label={x.label}
                disabled={
                  conditionalDisable
                    ? loading || values[dependsOnField] !== dependsOnValue
                    : loading
                }
                data={x.options} // Data to populate selects
              />
            );
          } else if (x.type === "HEADER") {
            ret = <Component key={x.label} label={x.label} />;
          } else if (x.type === "ASYNCSELECT") {
            ret = (
              <Component
                key={x.code}
                name={x.code}
                label={x.label}
                popover={true}
                popoverContent={
                  "If your child’s school/nursery is not in the search list please select “Other” from the list and enter their school/nursery address details below."
                }
                data={x.options}
                optionCode={"SchoolCodeURN"}
                optionName={"SchoolName"}
                optionValue={"Id"}
                placeholder={"Search for child School/Nursery"}
                disabled={
                  conditionalDisable
                    ? loading || values[dependsOnField] !== dependsOnValue
                    : loading
                }
              />
            );
          } else {
            ret = (
              <Component
                key={x.code}
                name={x.code}
                label={x.label}
                disabled={
                  conditionalDisable
                    ? loading || values[dependsOnField] !== dependsOnValue
                    : loading
                }
              />
            );
          }

          return ret;
        })}
      </>
    );
  }
}
