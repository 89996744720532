import * as yup from "yup";
import {
  stringRequired,
  stringMinMaxRequired,
  telephone,
  email,
  intRequired,
} from "../fields/index.js";
import moment from "moment";

export function formRendererValidation(props) {
  return yup.object().shape(
    props.config.fields.reduce((schema, field) => {
      if (field.meta && field.meta.includes("MANDATORY")) {
        schema[field.code] = stringRequired("This information is required");
      }

      // field.maxLength && field.minLength check returns false, if minLength is set to 0, therefore only use this if minLength setup has a value

      if (field.meta && field.maxLength) {
        schema[field.code] = stringMinMaxRequired(
          "This information is required",
          0,
          field.maxLength
        );
      }

      if (field.meta && field.meta.includes("TELEPHONE")) {
        schema[field.code] = telephone("This information is required");
      }

      if (field.meta && field.meta.includes("EMAIL")) {
        schema[field.code] = email("This information is required");
      }

      if (field.meta && field.meta.includes("NUMBER")) {
        schema[field.code] = intRequired("This information is required");
      }

      const dobSame =
        field.meta && field.meta.find((x) => x.includes("DOBSame"));

      if (field.meta && dobSame) {
        schema[field.code] = yup
          .string()
          .test({
            message: "This information is required",
            exclusive: true,
            name: "DOBSame",
            test: function (value) {
              if (!value) {
                return this.createError({
                  path: field.code,
                  message: "This information is required",
                });
              }

              const getDateFromMeta = dobSame.split("-");

              const dateCheckValid = moment(getDateFromMeta[1], "DD/MM/YYYY");

              const proposedDate = moment(this.parent[field.code]);

              const areDatesEq = dateCheckValid.isSame(proposedDate, "day");

              if (!areDatesEq) {
                return this.createError({
                  path: field.code,
                  message:
                    "The child DOB provided must match the information provided on the original referral form.",
                });
              } else {
                return true;
              }
            },
          })
          .typeError("This information is required");
      }

      const isConditional =
        field.meta && field.meta.find((x) => x.includes("CON"));

      // Conditional validation
      if (field.meta && isConditional) {
        const conditionalArrayValues = isConditional.split("-");
        const dependsOnField = conditionalArrayValues[1];
        const dependsOnValue = conditionalArrayValues[2];
        const dependsOnType = conditionalArrayValues[3];

        schema[field.code] = yup.string().test({
          test: function (value) {
            if (this.parent[dependsOnField] === dependsOnValue && !value) {
              return this.createError({
                path: field.code,
                message: "This information is required",
              });
            } else if (
              this.parent[dependsOnField] === dependsOnValue &&
              value
            ) {
              // Check specific types for txt, email and telephone are valid

              if (dependsOnType === "TXT") {
                let validLength = yup.string().max(500).isValidSync(value);
                if (validLength) {
                  return true;
                } else {
                  return this.createError({
                    path: field.code,
                    message:
                      "Text length cannot be greater than 500 characters.",
                  });
                }
              } else if (dependsOnType === "EMAIL") {
                let validEmail = yup.string().email().isValidSync(value);

                if (validEmail) {
                  return true;
                } else {
                  return this.createError({
                    path: field.code,
                    message: "Email is invalid",
                  });
                }
              } else if (dependsOnType === "TEL") {
                const tel = /^0\d{8,16}$/;
                const valueClean = value ? value.replace(/\s/g, "") : null;
                if (tel.test(valueClean)) {
                  return true;
                } else {
                  return this.createError({
                    path: field.code,
                    message: "Telephone number is invalid",
                  });
                }
              }
            } else {
              return true;
            }
          },
        });
      }

      return schema;
    }, {})
  );
}
