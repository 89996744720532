import { Alert, Row, Col } from "react-bootstrap";

export default function ErrorNotification(props) {
  let alertMessage = props.alertMessage;

  if (alertMessage !== undefined) {
    if (!Array.isArray(alertMessage)) {
      alertMessage = [alertMessage];
    }

    alertMessage = alertMessage.map((item, index) => (
      <li key={index}>{item}</li>
    ));
  }

  return (
    <Row>
      <Col>
        <Alert show={alertMessage !== undefined} variant="danger">
          <h4>Error - there is a problem</h4>
          <ul>{alertMessage}</ul>
        </Alert>
      </Col>
    </Row>
  );
}
