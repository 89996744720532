import * as yup from "yup";

export default function intRequired(label) {
  return yup
    .number()
    .label()
    .required(label)
    .integer()
    .typeError("Please enter a valid number");
}
