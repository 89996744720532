import React, { useEffect, useState } from "react";
import UserContext from "./context/userContext.js";
import { Container } from "react-bootstrap";
import Routing from "./components/routing/routing.js";
import Header from "./components/base/header.js";
import Footer from "./components/base/footer.js";
import User from "./components/base/user.js";
import { useNavigate } from "react-router-dom";

export default function App() {
  const [token, setToken] = useState();
  const [clientID, setClientID] = useState();
  const [accessCode, setAccessCode] = useState();

  const navigate = useNavigate();

  const loginProcess = (userDetail, accessCode) => {
    sessionStorage.setItem("token", userDetail.token);
    sessionStorage.setItem("accessCode", accessCode);
    sessionStorage.setItem("clientID", userDetail.clientID);

    setAccessCode(accessCode);
    setToken(userDetail.token);
    setClientID(userDetail.clientID);
  };

  const logoutProcess = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("accessCode");
    sessionStorage.removeItem("clientID");

    setToken(null);
    setAccessCode(null);
    setClientID(null);

    navigate("/");
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const clientID = sessionStorage.getItem("clientID");
    const accessCode = sessionStorage.getItem("accessCode");

    if (token) {
      setAccessCode(accessCode);
      setClientID(clientID);
      setToken(token);
    } else {
      setToken(null);
      setClientID(null);
      setAccessCode(null);
    }
  }, []);

  return (
    <>
      <Header />
      <div className="content">
        <Container>
          <UserContext.Provider
            value={{
              token,
              clientID,
              accessCode,
            }}
          >
            <User logoutProcess={logoutProcess} />
            <Routing
              loginProcess={loginProcess}
              logoutProcess={logoutProcess}
            />
          </UserContext.Provider>
        </Container>
      </div>
      <Footer />
    </>
  );
}
