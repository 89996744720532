import { useEffect, useState } from "react";
import { Button, Row, Col, Card, Alert } from "react-bootstrap";
import { useContext } from "react";
import UserContext from "../../context/userContext.js";
import { Formik, Form } from "formik";
import { formRendererValidation } from "../../validation/shapes/formRendererValidation.js";
import {
  submitAssessment,
  createUpdateAssessmentEntry,
} from "../../utils/sendRequest.js";
import ExitIcon from "../../assets/icons/exit.svg";
import SavedIcon from "../../assets/icons/cloud-saved.svg";
import SavingIcon from "../../assets/icons/cloud-saving.svg";
import SaveIcon from "../../assets/icons/cloud.svg";
import SaveIconGrey from "../../assets/icons/cloud-grey.svg";
import moment from "moment";
import { useParams } from "react-router-dom";
import ErrorNotification from "../base/errorNotification.js";
import { OrderedFormData } from "./orderedFormData.js";
import SessionExpiredModal from "./sessionExpiredModal.js";
import SaveModal from "./saveModal.js";

export default function FormRenderer(props) {
  const userDetail = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [serverError, setServerError] = useState();
  const { formType } = useParams();
  const [draft, setDraft] = useState();
  const [sessionExpired, setSessionExpired] = useState(false);
  const [formData, setFormData] = useState(false);

  const [saveModal, setSaveModalOpen] = useState(false);

  const closeModalNoRefresh = async () => {
    setSessionExpired(false);
    setSaveModalOpen(false);
  };

  const saveAssessmentDraft = async (values, touched, errors) => {
    const formFieldsToSave = {};

    Object.keys(values).forEach((field) => {
      if (values[field] && !errors[field]) {
        formFieldsToSave[field] = values[field];
      }
    });

    if (Object.keys(formFieldsToSave).length) {
      setLoading(true);
      try {
        const res = await createUpdateAssessmentEntry(
          userDetail.token,
          formType,
          userDetail.clientID,
          userDetail.accessCode,
          formFieldsToSave
        );

        setServerError(null);
        setDraft(res);
        setLoading(false);
      } catch (err) {
        if (
          (err.message && err.message.includes("Session has expired")) ||
          (err.message && err.message.includes("Invalid token"))
        ) {
          setSessionExpired(true);
          setServerError(err);
          setLoading(false);
        } else {
          setServerError(err);
          setLoading(false);
        }
      }
    } else {
      setServerError({
        message:
          "No initial form changes detected. Please update form values before saving a draft.",
      });
    }
  };

  const initValues = () => {
    let val;

    if (props.values) {
      //There are values so map to fields

      val = props.values.fields.reduce((x, field) => {
        //if value save as null in db, field.value is not returned.
        if (!field.value) {
          x[field.code] = "";
        } else {
          if (field.code === "ChildDOB") {
            x[field.code] = moment(field.value).toDate();
          } else {
            x[field.code] = field.value;
          }
        }

        return x;
      }, {});
    } else {
      val = props.config.fields.reduce((x, field) => {
        if (field.type !== "HEADER") {
          x[field.code] = "";
        }

        return x;
      }, {});
    }
    return val;
  };

  //Function passed to save modal
  const submitAssessmentEntry = async () => {
    try {
      setLoading(true);
      await submitAssessment(
        userDetail.token,
        formType,
        userDetail.clientID,
        userDetail.accessCode,
        formData
      );

      setLoading(false);
      setSaveModalOpen(false);
      setSuccess(true);

      setServerError(null);
    } catch (err) {
      if (
        (err.message && err.message.includes("Session has expired")) ||
        (err.message && err.message.includes("Invalid token"))
      ) {
        //Close save modal
        setSaveModalOpen(false);
        //Open session expired modal
        setSessionExpired(true);
        setLoading(false);
        setServerError(err);
      } else {
        setLoading(false);
        setServerError(err);
      }
    }
  };

  useEffect(() => {
    async function updateDraftStatus() {
      if (props.values) {
        //values so also set last time saved, as obj as this is whats returned on update
        setDraft({ datetime: props.values.updatedDateTime });
      }
    }
    updateDraftStatus();
  }, []);

  if (success) {
    return (
      <Row>
        <Col xs={12} sm={12} md={2}>
          <div className="mt-3 sticky-sidebar sticky-top">
            <h3>Help</h3>
            <div className="mb-3 mt-3">
              <Button variant="dark" disabled={loading} href={"/"} size="sm">
                <>
                  <img
                    src={ExitIcon}
                    alt="save-icon"
                    className="icon-size me-2"
                  />
                  Exit form
                </>
              </Button>
            </div>
          </div>
        </Col>

        <Col xs={12} sm={12} md={9}>
          <Alert variant="success">
            Your form is successfully submitted thank you. Please exit the form
            to continue to the next step or log out to end your session.
          </Alert>
        </Col>
      </Row>
    );
  } else {
    return (
      <>
        {sessionExpired && (
          <SessionExpiredModal
            show={true}
            onHide={() => {
              setServerError(null);
              setSessionExpired(false);
            }}
            loginProcess={props.loginProcess}
            closeModalNoRefresh={closeModalNoRefresh}
          />
        )}

        {saveModal && (
          <SaveModal
            show={true}
            onHide={() => {
              setServerError(null);
              setSaveModalOpen(false);
            }}
            submitAssessmentEntry={submitAssessmentEntry}
            errors={serverError}
            loading={loading}
            closeModalNoRefresh={closeModalNoRefresh}
          />
        )}

        <Formik
          initialValues={initValues()}
          validationSchema={formRendererValidation(props)}
          onSubmit={async (values) => {
            setFormData(values);
            setServerError(null);
            setSaveModalOpen(true);
          }}
        >
          {({ touched, errors, values }) => {
            return (
              <Row>
                <Col xs={12} sm={12} md={2}>
                  <div className="mt-3 sticky-sidebar sticky-top">
                    <h3>Help</h3>
                    <div className="mb-3 mt-3">
                      <Button
                        variant="dark"
                        disabled={loading}
                        href={"/"}
                        size="sm"
                      >
                        <>
                          <img
                            src={ExitIcon}
                            alt="save-icon"
                            className="icon-size me-2"
                          />
                          Exit form
                        </>
                      </Button>
                    </div>
                    <p>
                      Press the save draft button to save your progress. You
                      will be able to return to this later.
                    </p>
                    <h5>Status</h5>
                    <p className="text-muted mb-3 mt-3">
                      {" "}
                      <img
                        src={
                          loading
                            ? SavingIcon
                            : draft
                            ? SavedIcon
                            : SaveIconGrey
                        }
                        alt="draft-save-icon"
                        className="icon-size me-2"
                      />
                      {loading
                        ? "Saving"
                        : draft
                        ? `Draft last saved ${draft.datetime}`
                        : "Empty"}
                    </p>
                    {serverError && (
                      <ErrorNotification alertMessage={serverError.message} />
                    )}
                    <div className="mb-3 text-right">
                      <Button
                        variant="primary"
                        size="sm"
                        disabled={loading}
                        onClick={async () =>
                          await saveAssessmentDraft(values, touched, errors)
                        }
                      >
                        <>
                          <img
                            src={SaveIcon}
                            alt="save-icon"
                            className="icon-size me-2"
                          />
                          Save draft
                        </>
                      </Button>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={9}>
                  {serverError && (
                    <ErrorNotification alertMessage={serverError.message} />
                  )}

                  <Card>
                    <Card.Header className="bg-light h4">
                      {`${props.config.title} - ${props.config.client}`}
                    </Card.Header>

                    <Card.Body>
                      <Card.Text>{props.config.mainHeading}</Card.Text>
                    </Card.Body>
                  </Card>

                  <Form noValidate autoComplete="off">
                    <OrderedFormData
                      data={props.config.fields}
                      complete={false}
                      loading={loading}
                    />

                    {/* Checks if there are errors and fields have been touched */}
                    {Object.keys(touched).length > 0 &&
                      Object.keys(errors).length > 0 && (
                        <p className="customError mt-4">
                          Please complete all required fields (*)
                        </p>
                      )}
                    <Button type="submit" variant="success" disabled={loading}>
                      {loading ? (
                        <span>Submitting...</span>
                      ) : (
                        <span>Submit</span>
                      )}
                    </Button>
                  </Form>
                </Col>
              </Row>
            );
          }}
        </Formik>
      </>
    );
  }
}
