import { useContext, useState, useEffect } from "react";
import UserContext from "../../context/userContext.js";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Alert,
  Popover,
  OverlayTrigger,
} from "react-bootstrap";
import ErrorNotification from "../base/errorNotification.js";
import LoadingSpinner from "../base/loadingSpinner.js";
import CompleteIcon from "../../assets/icons/complete.svg";
import InCompleteIcon from "../../assets/icons/incomplete.svg";
import RefreshIcon from "../../assets/icons/refresh.svg";
import InformationIcon from "../../assets/icons/information.svg";
import { getAssessmentPack } from "../../utils/sendRequest.js";
import { useNavigate } from "react-router-dom";

//import SessionExpiredModal from "../base/sessionExpiredModal.js";

export default function BCHCOneStopForm({ logoutProcess }) {
  const userDetail = useContext(UserContext);

  const [serverError, setServerError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [refreshAssessTableLoading, setRefreshTableLoading] = useState(false);
  const [assessPack, setAssessPack] = useState();
  const [priorityForm, setPriorityForm] = useState();
  const navigate = useNavigate();

  //const [sessionExpired, setSessionExpired] = useState(false);

  const popover = () => {
    const getPriorityOneAssessment = assessPack.assessments.find(
      (x) => x.priority === 1
    );

    return (
      <Popover id="popover-contained">
        <Popover.Header as="h3">
          Please complete priority form "{getPriorityOneAssessment.title}"
          before continuing.
        </Popover.Header>
      </Popover>
    );
  };

  // const closeModalNoRefresh = async () => {
  //   setSessionExpired(false);
  // };

  const checkPriorityComplete = (assess) => {
    // Each assesmsent pass in to check, priory 1 always enabled, other forms check if priority one is not complete then disable.

    let priorityComplete = true;

    if (assess.priority !== 1) {
      const getPriorityOneAssessment = assessPack.assessments.find(
        (x) => x.priority === 1
      );

      if (getPriorityOneAssessment && !getPriorityOneAssessment.completed) {
        priorityComplete = false;
      }
    }

    return priorityComplete;
  };

  const loadQuestionnaireRoute = async (assess) => {
    if (assess.completed) {
      //completed
      navigate(`form/complete/${assess.code}/`);
    } else {
      navigate(`form/${assess.code}/`);
    }
  };

  const refreshAssessPack = async () => {
    setRefreshTableLoading(true);

    try {
      const res = await getAssessmentPack(
        userDetail.token,
        userDetail.accessCode
      );

      const getPriorityOneAssessment = res.assessments.find(
        (x) => x.priority === 1
      );

      if (getPriorityOneAssessment && !getPriorityOneAssessment.completed) {
        setPriorityForm(getPriorityOneAssessment.title);
      } else {
        setPriorityForm(null);
      }

      setAssessPack(res);

      setRefreshTableLoading(false);
      setServerError(null);
    } catch (err) {
      if (
        (err.message && err.message.includes("Session has expired")) ||
        (err.message && err.message.includes("Invalid token"))
      ) {
        logoutProcess();
        setPriorityForm(null);
        setRefreshTableLoading(false);
      } else {
        setPriorityForm(null);
        setRefreshTableLoading(false);
        setServerError(err);
      }
    }
  };

  useEffect(() => {
    async function getFormOptions() {
      try {
        const res = await getAssessmentPack(
          userDetail.token,
          userDetail.accessCode
        );

        const getPriorityOneAssessment = res.assessments.find(
          (x) => x.priority === 1
        );

        //If not priorty form dont set, e.g demographics in NDP forms
        if (getPriorityOneAssessment && !getPriorityOneAssessment.completed) {
          setPriorityForm(getPriorityOneAssessment.title);
        } else {
          setPriorityForm(null);
        }

        setAssessPack(res);

        setLoading(false);
      } catch (err) {
        setServerError(err);
        setLoading(false);
        setPriorityForm(null);

        if (
          (err.message && err.message.includes("Session has expired")) ||
          (err.message && err.message.includes("Invalid token"))
        ) {
          //setServerError(err);
          logoutProcess();
          //setSessionExpired(true);
          setLoading(false);
        } else {
          setLoading(false);
          setServerError(err);
        }
      }
    }
    getFormOptions();
  }, []);

  if (loading) {
    return (
      <Row>
        <Col xs={12} sm={12} md={10}>
          <LoadingSpinner />
        </Col>
      </Row>
    );
  } else {
    return (
      <>
        {serverError && (
          <ErrorNotification alertMessage={serverError.message} />
        )}

        {/* {sessionExpired && (
          <SessionExpiredModal
            show={true}
            onHide={() => {
              navigate(0);
              setSessionExpired(true);
            }}
            loginProcess={loginProcess}
            closeModalNoRefresh={closeModalNoRefresh}
          />
        )} */}

        <Row>
          <Col xs={12} sm={12} md={10}>
            <Card>
              <Card.Header className="bg-light h4">
                {assessPack ? assessPack.title : "N/A"}
              </Card.Header>

              <Card.Body>
                <Card.Text>
                  {assessPack ? assessPack.description : "N/A"}
                </Card.Text>
              </Card.Body>
            </Card>

            {priorityForm && (
              <Alert variant={"info"}>
                <p>
                  <strong>
                    {" "}
                    <img
                      src={InformationIcon}
                      alt="refresh-icon"
                      className="icon-size"
                    />{" "}
                    Please complete priority form "{priorityForm}" before
                    continuing.
                  </strong>
                </p>
              </Alert>
            )}

            <Button
              size="sm"
              className="mb-2 mt-2"
              variant="light"
              disabled={refreshAssessTableLoading}
              onClick={async () => await refreshAssessPack()}
            >
              <>
                <img
                  src={RefreshIcon}
                  alt="refresh-icon"
                  className="icon-size"
                />{" "}
                Refresh
              </>
            </Button>

            {refreshAssessTableLoading ? (
              <Row>
                <Col xs={12} sm={12} md={10}>
                  <LoadingSpinner />
                </Col>
              </Row>
            ) : !serverError ? (
              <Table responsive="sm" id="questionnaires">
                <thead>
                  <tr>
                    <th>Form</th>
                    <th></th>
                    <th>Complete</th>
                  </tr>
                </thead>
                <tbody>
                  {assessPack.assessments.map((x, index) => (
                    <tr key={`el_${index}`}>
                      {!checkPriorityComplete(x) ? (
                        <td className="text-muted">{x.title}</td>
                      ) : (
                        <td>{x.title}</td>
                      )}
                      <td>
                        {/* Disabled buttons need span tags to trigger overlay */}
                        {!checkPriorityComplete(x) ? (
                          <OverlayTrigger placement="right" overlay={popover()}>
                            <span>
                              {" "}
                              <Button
                                size="sm"
                                disabled={true}
                                style={{ pointerEvents: "none" }}
                                variant={
                                  x.completed
                                    ? "dark"
                                    : x.entry && !x.completed
                                    ? "primary"
                                    : "warning"
                                }
                              >
                                {x.completed
                                  ? "Press to review form"
                                  : x.entry && !x.completed
                                  ? "Press to continue form"
                                  : "Press to start form"}
                              </Button>
                            </span>
                          </OverlayTrigger>
                        ) : (
                          <Button
                            size="sm"
                            disabled={refreshAssessTableLoading}
                            variant={
                              x.completed
                                ? "dark"
                                : x.entry && !x.completed
                                ? "primary"
                                : "warning"
                            }
                            onClick={async () =>
                              await loadQuestionnaireRoute(x)
                            }
                          >
                            {x.completed
                              ? "Press to review form"
                              : x.entry && !x.completed
                              ? "Press to continue form"
                              : "Press to start form"}
                          </Button>
                        )}
                      </td>

                      <td>
                        <img
                          src={x.completed ? CompleteIcon : InCompleteIcon}
                          alt="complete-icon"
                          className="icon-size grey-out"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : null}
          </Col>
        </Row>
      </>
    );
  }
}
