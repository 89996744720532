import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";
import RadioField from "./radioField.js";
import CustomFieldError from "./customFieldError.js";

export default function RadioFieldArray({ label, ...props }) {
  const { values } = useFormikContext();

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Check.Label className="mb-2">{label}</Form.Check.Label>

        {props.data.map((item, index) => (
          <RadioField
            disabled={props.disabled}
            name={props.name}
            value={item.value}
            label={item.code}
            type="radio"
            key={`${item.name}_${item.code}`}
          />
        ))}

        <CustomFieldError name={props.name} />
      </Form.Group>
    </>
  );
}
