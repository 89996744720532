import React, { useContext } from "react";
import UserContext from "../../context/userContext.js";
import { Button, Row, Col } from "react-bootstrap";

export default function User({ logoutProcess }) {
  const userDetail = useContext(UserContext);

  if (userDetail.token) {
    return (
      <Row>
        <Col xs={12} sm={12} md={8}>
          <Button variant="success" onClick={logoutProcess} className="mb-3">
            Logout
          </Button>
        </Col>
      </Row>
    );
  } else {
    return null;
  }
}
