const { hostname, protocol } = window.location;

const host =
  process.env.NODE_ENV === "production"
    ? `https://api.bchcreferrals.nhs.uk/oneStop`
    : `${protocol}//${hostname}:8443/oneStop`;

function send(path, token, model, method) {
  const url = host + path;

  const headers = {
    "Content-Type": "application/json",
  };

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  const request = {
    credentials: "omit",
    method: method === "POST" ? "POST" : "GET",
    headers,
    referrer: "no-referrer",
  };

  if (model) {
    request["body"] = JSON.stringify(model);
  }

  return new Promise((resolve, reject) => {
    fetch(url, request)
      .then((res) => {
        if (res.status === 200) {
          return res.json().then(resolve).catch(reject);
        } else if (res.status === 204) {
          return resolve(null);
        } else {
          return res.json().then(reject).catch(reject);
        }
      })
      .catch(reject);
  });
}

export function login(pin, accessCode) {
  return send("/login", null, { pin, accessCode }, "POST");
}

export function getAssessmentPack(token, accessCode) {
  return send("/getAssessmentPack", token, { accessCode }, "POST");
}

export function getClientDetails(token, clientID) {
  return send("/getClientDetails", token, { clientID }, "GET");
}

export function getAssessmentEntry(token, formCode, clientID, accessCode) {
  return send(
    "/getAssessmentEntry",
    token,
    { formCode, clientID, accessCode },
    "POST"
  );
}

export function getAssessmentDefinition(token, formCode, accessCode) {
  return send(
    "/getAssessmentDefinition",
    token,
    { formCode, accessCode },
    "POST"
  );
}

export function getCompletedAssessment(token, formCode, clientID, accessCode) {
  return send(
    "/getCompletedAssessment",
    token,
    { formCode, clientID, accessCode },
    "POST"
  );
}

export function submitAssessment(
  token,
  formCode,
  clientID,
  accessCode,
  values
) {
  return send(
    "/submitAssessmentEntry",
    token,
    { formCode, clientID, accessCode, values },
    "POST"
  );
}

export function createUpdateAssessmentEntry(
  token,
  formCode,
  clientID,
  accessCode,
  values
) {
  return send(
    "/createUpdateAssessmentEntry",
    token,
    { formCode, clientID, accessCode, values },
    "POST"
  );
}

export function createNewAssessmentEntry(
  token,
  formCode,
  clientID,
  accessCode
) {
  return send(
    "/createNewAssessmentEntry",
    token,
    { formCode, clientID, accessCode },
    "POST"
  );
}
