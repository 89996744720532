import { Form } from "react-bootstrap";
import { useField } from "formik";

export default function RadioField({ label, ...props }) {
  const [field, meta] = useField(props);

  return (
    <Form.Check
      {...field}
      {...props}
      type="radio"
      label={label}
      aria-required={true}
      isInvalid={meta.touched && meta.error}
    ></Form.Check>
  );
}
