import * as yup from "yup";

const re = /^0\d{8,15}$/;

export default function telephone(label) {
  return yup
    .string()
    .test(
      "Invalid telephone number",
      "Please enter a valid telephone number",
      (value) => {
        const valueClean = value ? value.replace(/\s/g, "") : null;
        if (re.test(valueClean)) {
          return true;
        } else {
          return false;
        }
      }
    )
    .label(label)
    .required()
    .typeError(label);
}
