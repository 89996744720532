import { Row, Col, Card, Button } from "react-bootstrap";
import ExitIcon from "../../assets/icons/exit.svg";
import PrinterIcon from "../../assets/icons/printer.svg";

import { OrderedFormData } from "./orderedFormData.js";

export default function FormRendererComplete(props) {
  return (
    <Row>
      <Col xs={12} sm={12} md={2}>
        <div className="mt-3 sticky-sidebar sticky-top">
          <h3>Help</h3>
          <div className="mb-3 mt-3">
            <Button variant="dark" href={"/"} size="sm">
              <>
                <img
                  src={ExitIcon}
                  alt="exit-icon"
                  className="icon-size me-2"
                />
                Exit form
              </>
            </Button>
          </div>
          <div className="mb-3 mt-3">
            <Button variant="primary" size="sm" onClick={() => window.print()}>
              <>
                <img
                  src={PrinterIcon}
                  alt="printer icon"
                  className="icon-size me-2"
                />
                Print form
              </>
            </Button>
          </div>
        </div>
      </Col>
      <Col xs={12} sm={12} md={9}>
        <Card>
          <Card.Header className="bg-light h4">
            {`${props.config.title} - ${props.config.client.name}`}
          </Card.Header>
          <Card.Body>
            <Card.Text>{props.config.mainHeading}</Card.Text>
          </Card.Body>

          <Card.Body>
            <OrderedFormData
              data={props.config.fields}
              complete={true}
              loading={false}
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
