import { useState } from "react";
import { Button, Form, Row, Col, Card } from "react-bootstrap";
import { login } from "../../utils/sendRequest.js";
import * as FormValidator from "../../validation/index.js";
import { Formik } from "formik";
import InputField from "../formFields/inputField.js";

export default function Login({ loginProcess }) {
  const [authenticating, setAuthenticating] = useState(false);
  const [failed, setFailed] = useState(false);
  const [serverError, setServerError] = useState();

  return (
    <Row>
      <Col xs={12} sm={12} md={8}>
        <Card>
          <Card.Header className="bg-light h4">BCHCOneStop Login</Card.Header>
          {failed && (
            <Card bg="danger" text="light" className="mb-3 mt-3">
              <Card.Header>Error</Card.Header>
              <Card.Body>
                {serverError ? serverError.message : "Error logging in"}
              </Card.Body>
            </Card>
          )}
          <Card.Body>
            <Formik
              initialValues={{
                pin: "",
                accessCode: "",
              }}
              validationSchema={FormValidator.login}
              onSubmit={(values) => {
                setAuthenticating(true);

                login(values.pin, values.accessCode)
                  .then((res) => {
                    setAuthenticating(false);
                    loginProcess(res, values.accessCode);
                  })
                  .catch((error) => {
                    setAuthenticating(false);
                    setFailed(true);
                    setServerError(error);
                  });
              }}
            >
              {({ handleSubmit }) => {
                return (
                  <Form noValidate onSubmit={handleSubmit} autoComplete="off">
                    <InputField
                      name="accessCode"
                      type="text"
                      label="Access Code*"
                      placeholder="Please enter your access code"
                      disabled={authenticating}
                    />

                    <InputField
                      name="pin"
                      type="text"
                      label="Pin*"
                      placeholder="Please enter your pin"
                      disabled={authenticating}
                    />

                    <Button
                      type="submit"
                      variant="success"
                      disabled={authenticating}
                    >
                      {authenticating ? (
                        <span>Logging in...</span>
                      ) : (
                        <span>Login</span>
                      )}
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
