import { Form } from "react-bootstrap";
import { useField, useFormikContext } from "formik";

export default function CheckboxField({ label, ...props }) {
  const [field, meta] = useField(props);

  return (
    <>
      <Form.Check
        {...field}
        {...props}
        type="checkbox"
        label={label}
        aria-required={true}
        checked={field.value}
        isInvalid={meta.touched && meta.error}
      ></Form.Check>
    </>
  );
}
