import { Button, Modal, Card } from "react-bootstrap";

export default function SaveModal({
  show,
  onHide,
  submitAssessmentEntry,
  errors,
  loading,
  closeModalNoRefresh,
}) {
  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>Submit Assessment</Modal.Title>
        <Button
          type="button"
          className="btn-close"
          aria-label="Close"
          variant="light"
          disabled={loading}
          onClick={() => closeModalNoRefresh()}
        ></Button>
      </Modal.Header>
      <Modal.Body>
        Please note that once submitted, forms cannot be re-submitted. Press
        submit to register your form.
        <Card className="mt-2">
          {errors && (
            <Card bg="danger" text="light" className="mb-3 mt-3">
              <Card.Header>Error</Card.Header>
              <Card.Body>
                {errors ? errors.message : "Error submitting assessment"}
              </Card.Body>
            </Card>
          )}
          <Card.Body>
            <Button
              type="submit"
              variant="success"
              id="submitAssessment"
              disabled={loading}
              onClick={() => submitAssessmentEntry()}
            >
              {loading ? <span>Submitting...</span> : <span>Submit</span>}
            </Button>
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  );
}
