import { Button, Modal, Card, Form } from "react-bootstrap";
import * as FormValidator from "../../validation/index.js";
import { Formik } from "formik";
import { useState } from "react";
import { login } from "../../utils/sendRequest.js";

import InputField from "../formFields/inputField.js";

export default function SessionExpiredModal({
  show,
  onHide,
  loginProcess,
  closeModalNoRefresh,
}) {
  const [authenticating, setAuthenticating] = useState(false);
  const [failed, setFailed] = useState(false);
  const [serverError, setServerError] = useState();

  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>Session Expired</Modal.Title>
        <Button
          type="button"
          className="btn-close"
          aria-label="Close"
          variant="light"
          disabled={authenticating}
          onClick={() => closeModalNoRefresh()}
        ></Button>
      </Modal.Header>
      <Modal.Body>
        Your session has expired. Please enter your details to resume or close
        this window and log out to end your session.
        <Card className="mt-2">
          {failed && (
            <Card bg="danger" text="light" className="mb-3 mt-3">
              <Card.Header>Error</Card.Header>
              <Card.Body>
                {serverError ? serverError.message : "Error logging in"}
              </Card.Body>
            </Card>
          )}
          <Card.Body>
            <Formik
              initialValues={{
                pin: "",
                accessCode: "",
              }}
              validationSchema={FormValidator.login}
              onSubmit={(values) => {
                setAuthenticating(true);
                login(values.pin, values.accessCode)
                  .then((res) => {
                    setAuthenticating(false);
                    loginProcess(res, values.accessCode);
                    onHide();
                  })

                  .catch((error) => {
                    setAuthenticating(false);
                    setFailed(true);
                    setServerError(error);
                  });
              }}
            >
              {({ handleSubmit }) => {
                return (
                  <Form noValidate onSubmit={handleSubmit} autoComplete="off">
                    <InputField
                      name="accessCode"
                      type="text"
                      label="Access Code*"
                      placeholder="Please enter your access code"
                      disabled={authenticating}
                    />

                    <InputField
                      name="pin"
                      type="text"
                      label="Pin*"
                      placeholder="Please enter your pin"
                      disabled={authenticating}
                    />

                    <Button
                      type="submit"
                      variant="success"
                      disabled={authenticating}
                    >
                      {authenticating ? (
                        <span>Logging in...</span>
                      ) : (
                        <span>Login</span>
                      )}
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  );
}
