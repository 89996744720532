import * as yup from 'yup';

export default function stringMinMaxRequired(label, min, max) {
  return yup
    .string()
    .min(min)
    .max(max, `Field must be at most ${max} characters`)
    .label()
    .required(label)
    .typeError(label);
}
