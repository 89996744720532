import { Form, OverlayTrigger, Popover } from "react-bootstrap";
import { useField } from "formik";
import AsyncSelect from "react-select/async";
import InformationIcon from "../../assets/icons/info-bold.svg";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";

export default function AsyncSelectField({ label, ...props }) {
  const { data } = props;
  const [field, meta, helper] = useField(props);
  const { setValue } = helper;
  const { values } = useFormikContext();

  const [initValue, setInitValue] = useState("");

  const popover = (
    <Popover id="popover-contained">
      <Popover.Header as="h3">{props.popoverContent}</Popover.Header>
    </Popover>
  );

  const setAsyncValue = (option) => {
    if (props.name === "SchoolSearchN") {
      if (option.TelephoneNum && option.SchoolStreet) {
        values.SchoolTelephone = option.TelephoneNum;
        values.SchoolAddress = `${option.SchoolStreet}, ${option.SchoolPostCode}`;
        values.SchoolName = "";
      } else {
        values.SchoolTelephone = "";
        values.SchoolAddress = "";
      }
    }
    setInitValue(option);
    setValue(option[props.optionName]);
  };

  useEffect(() => {
    async function updateValue() {
      if (values[props.name]) {
        const val = data.find(
          (x) => x[props.optionName] === values[props.name]
        );

        setInitValue(val);
      }
    }
    updateValue();
  }, []);

  return (
    <Form.Group className="form-input-spacing mt-2" controlId={props.name}>
      {props.popover ? (
        <OverlayTrigger
          trigger={["hover", "click", "focus"]}
          placement="top"
          overlay={popover}
        >
          <Form.Check.Label className="tool-hover mb-2">
            {label}{" "}
            <img
              src={InformationIcon}
              alt="information-icon"
              className="icon-size"
            />
          </Form.Check.Label>
        </OverlayTrigger>
      ) : (
        <Form.Label>{label}</Form.Label>
      )}

      <AsyncSelect
        value={initValue}
        defaultOptions={data.sort((a, b) => {
          if (a[props.optionName] < b[props.optionName]) {
            return -1;
          }
          if (a[props.optionName] > b[props.optionName]) {
            return 1;
          }
          return 0;
        })}
        name={props.name}
        isDisabled={props.disabled}
        loadOptions={(inputValue, callback) => {
          const re = /^\d{0,6}$/;
          const reLet = /^[a-zA-Z]{1}\d{0,6}$/;
          if (
            re.test(inputValue) ||
            (reLet.test(inputValue) && inputValue.length > 1)
          ) {
            callback(
              data.filter((x) =>
                x[props.optionCode]
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              )
            );
          } else {
            callback(
              data.filter((x) =>
                x[props.optionName]
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              )
            );
          }
        }}
        getOptionLabel={(option) => option[props.optionName]}
        getOptionValue={(option) => option[props.optionValue]}
        isSearchable={true}
        maxMenuHeight={150}
        styles={{
          control: (styles) => ({
            ...styles,
            borderColor:
              meta.error && meta.touched ? "#dc3545" : styles.borderColor,
            "&:hover": {
              borderColor:
                meta.error && meta.touched ? "#dc3545" : styles.borderColor,
            },
          }),
        }}
        placeholder={props.placeholder}
        onChange={(option) => setAsyncValue(option)}
        noOptionsMessage={() => "No results"}
      />
      {meta.touched && meta.error && (
        <p className="customError">{meta.error}</p>
      )}
    </Form.Group>
  );
}
