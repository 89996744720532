import { useField } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form } from "react-bootstrap";

export default function DateTimePickerField({ label, ...props }) {
  const [field, meta, helper] = useField(props);
  const { setValue } = helper;

  return (
    <Form.Group className="form-input-spacing mt-2" controlId={props.name}>
      <Form.Label>{label}</Form.Label>
      <DatePicker
        {...field}
        {...props}
        selected={field.value}
        onChange={(date) => setValue(date)}
        showTimeSelect
        dateFormat="dd/MM/yyyy HH:mm"
        placeholderText="DD/MM/YYYY"
        timeFormat="HH:mm"
        timeIntervals={15}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        wrapperClassName={
          meta.touched && meta.error
            ? "form-control is-invalid"
            : "form-control"
        }
      />
      {meta.touched && meta.error && (
        <p className="customError">{meta.error}</p>
      )}
    </Form.Group>
  );
}
