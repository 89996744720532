import * as yup from 'yup';

export default function email(label) {
  return yup
    .string()
    .max(75)
    .email('This information is required. Please enter a valid email address')
    .label()
    .required(label)
    .typeError(label);
}
