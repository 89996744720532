import { useEffect, useState, useContext } from "react";
import FormRenderer from "../../base/formRenderer.js";
import LoadingSpinner from "../../base/loadingSpinner.js";
import { Col, Row, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import UserContext from "../../../context/userContext.js";
import ErrorNotification from "../../base/errorNotification.js";
import {
  getAssessmentEntry,
  getAssessmentDefinition,
} from "../../../utils/sendRequest.js";
import ExitIcon from "../../../assets/icons/exit.svg";
import SessionExpiredModal from "../../base/sessionExpiredModal.js";
import { useNavigate } from "react-router-dom";

export default function Assessment({ loginProcess }) {
  const [formValues, setFormValues] = useState();
  const [formData, setFormData] = useState();
  const [loading, setLoading] = useState(true);
  const [serverError, setServerError] = useState();
  const { formType } = useParams();
  const userDetail = useContext(UserContext);
  const navigate = useNavigate();

  const [sessionExpired, setSessionExpired] = useState(false);

  const closeModalNoRefresh = async () => {
    setSessionExpired(false);
  };

  useEffect(() => {
    async function getFormOptions() {
      try {
        const res = await getAssessmentDefinition(
          userDetail.token,
          formType,
          userDetail.accessCode
        );
        setFormData(res);

        //Check if the form has an entry, new forms are blank therefore no need to call API
        if (res.latestForm.entry) {
          const val = await getAssessmentEntry(
            userDetail.token,
            formType,
            userDetail.clientID,
            userDetail.accessCode
          );

          setFormValues(val);
        }

        setLoading(false);
      } catch (err) {
        if (
          (err.message && err.message.includes("Session has expired")) ||
          (err.message && err.message.includes("Invalid token"))
        ) {
          setSessionExpired(true);
          setServerError(err);
          setLoading(false);
        } else {
          setServerError(err);
          setLoading(false);
        }
      }
    }
    getFormOptions();
  }, []);

  if (loading) {
    return (
      <Row>
        <Col xs={12} sm={12} md={8}>
          <LoadingSpinner />
        </Col>
      </Row>
    );
  } else {
    if (serverError) {
      return (
        <>
          {sessionExpired && (
            <SessionExpiredModal
              show={true}
              onHide={() => {
                navigate(0);
                setSessionExpired(true);
              }}
              loginProcess={loginProcess}
              closeModalNoRefresh={closeModalNoRefresh}
            />
          )}

          <Row>
            <Col xs={12} sm={12} md={2}>
              <div className="mt-3 sticky-sidebar sticky-top">
                <h3>Help</h3>
                <div className="mb-3 mt-3">
                  <Button
                    variant="dark"
                    disabled={loading}
                    href={"/"}
                    size="sm"
                  >
                    <>
                      <img
                        src={ExitIcon}
                        alt="save-icon"
                        className="icon-size me-2"
                      />
                      Exit form
                    </>
                  </Button>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={9}>
              <ErrorNotification alertMessage={serverError.message} />
            </Col>
          </Row>
        </>
      );
    } else {
      return (
        <FormRenderer
          config={formData}
          values={formValues}
          loginProcess={loginProcess}
        />
      );
    }
  }
}
