import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";
import CheckBoxField from "./checkBoxField.js";
import CustomFieldError from "./customFieldError.js";

export default function CheckboxFieldArray({ label, ...props }) {
  const { values } = useFormikContext();

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Check.Label className="mb-2">{label}</Form.Check.Label>

        {props.data.map((item, index) => (
          <CheckBoxField
            key={index}
            name={props.name}
            value={item.code}
            label={item.description}
            id={item.description}
            type="checkbox"
            disabled={
              props.disabled ||
              (item.description !== props.disabledValueDescription &&
                values[props.name].length > 0 &&
                values[props.name].includes(props.disabledValueCode))
            }
          />
        ))}

        <CustomFieldError name={props.name} />
      </Form.Group>
    </>
  );
}
