import React, { useContext } from "react";
import UserContext from "../../context/userContext.js";
import { Routes, Route, Link } from "react-router-dom";
import Login from "../authentication/login";
import BCHCOneStop from "../bchcOneStopForm/bchcOneStopForm.js";
import Assessment from "../bchcOneStopForm/forms/assessment.js";
import AssessmentComplete from "../bchcOneStopForm/forms/assessmentComplete.js";

export default function Routing({ loginProcess, logoutProcess }) {
  const userDetail = useContext(UserContext);
  if (userDetail.token) {
    return (
      <Routes>
        <Route
          path="/"
          element={<BCHCOneStop logoutProcess={logoutProcess} />}
        />
        <Route
          path="/form/:formType/"
          element={<Assessment loginProcess={loginProcess} />}
        />
        <Route
          path="/form/complete/:formType/"
          element={<AssessmentComplete loginProcess={loginProcess} />}
        />
        <Route
          path="*"
          element={
            <main>
              <h1 className="mt-2 mb-4">Page not found</h1>
              <p>
                Please visit the <Link to="/">home page.</Link>
              </p>
            </main>
          }
        />
      </Routes>
    );
  } else if (userDetail.token === null) {
    return <Login loginProcess={loginProcess} />;
  } else {
    return <></>;
  }
}
